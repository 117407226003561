/* GENERAL
----------------------------------------------------*/
html {
	height:100%;
}

body {
	color: #171717;
	font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	font-weight: 300;
	height:100%;
	letter-spacing: 0;
	line-height: 1.7;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	-ms-overflow-style:-ms-autohiding-scrollbar;
}

.font-hk {
	font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

b, strong {
    font-weight: 500;
}

@keyframes linkhover {
  0%   {background-size: 100% 1px,0 1px;}
  100% {background-size: 0 1px,100% 1px}
}

.copy a {
	color: #171717;
	font-weight: 500;
	background-image: linear-gradient(transparent,transparent),linear-gradient(#000,#000);
    background-size: 100% 1px,0 1px;
    background-position: 100% 100%,0 100%;
    background-repeat: no-repeat;
    text-decoration: none;
}

.copy a:hover {
    animation-name: linkhover;
    animation-duration: .4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

a.link,
a.group .link {
	background-image: linear-gradient(transparent,transparent),linear-gradient(#000,#000);
    background-size: 100% 1px,0 1px;
    background-position: 100% 100%,0 100%;
    background-repeat: no-repeat;
    text-decoration: none;
}

a.link.link-gray-200 {
	background-image: linear-gradient(transparent,transparent),linear-gradient(#E4E4E7,#E4E4E7);
}

a.link.link-gray-300 {
	background-image: linear-gradient(transparent,transparent),linear-gradient(#D4D4D8,#D4D4D8);
}

a.link-white,
a.text-white.link,
.bg-transparent a.link {
	background-image: linear-gradient(transparent,transparent),linear-gradient(#fff,#fff);
}

a.link-black,
.text-black a.link {
	background-image: linear-gradient(transparent,transparent),linear-gradient(#000,#000);
}

a.link:hover,
a.group:hover .link {
    animation-name: linkhover;
    animation-duration: .4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

a.link svg,
a.group .link svg {
	transition: color .3s ease-in-out;
}

a.link:hover svg,
a.group:hover .link svg {
	color: #171717;
}

a.text-white.link:hover i {
	color: #fff;
}

.icon-circle,
a > .link-circle {
	background: #b8c584;
	color: #fff;
}
.icon-circle-gray,
a > .link-circle-gray {
	background: #e7e5e4;
	color: #fff;
}

a:hover svg.link-arrow,
a:hover > .link-circle,
a:hover > .link-circle-gray {
	background: #fff;
	color: #171717;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

a:hover svg.spin,
a:hover > .link-circle svg.spin,
a:hover > .link-circle-gray svg.spin {
    animation-name: spin;
    animation-duration: .25s;
    animation-timing-function: linear;
    animation-fill-mode: infinite;
}

/* -- For screen readers */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* -- A subtle focus style for keyboard-input elements */
.text-input:focus {
    outline: 2px dotted #171717;
    outline-offset: 6px;
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus {
  outline: none;
}

html.user-is-tabbing *:focus {
  outline: 2px dotted #171717;
  outline: 2px dotted -webkit-focus-ring-color;
  outline-offset: 6px;
}




/* NAVBAR
----------------------------------------------------*/

nav svg#logo {
	height: auto;
	width: 150px;
}

.header_button.-type-social {
    display: inline-block;
    vertical-align: middle;
    color: #000;
    margin: -4px 0 0 0;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    font-size: 1.5em;
    color: inherit;
}

.pt-navbar {
    padding-top: 70px;
}

@media (min-width: 1280px) {
	.pt-navbar {
	    padding-top: 78px;
	}
}

.hamburger:hover{
  cursor: pointer;
}

@keyframes openR1 { 
	0% { 
		-webkit-transform: translateX(0px) translateY(0px) rotate(0deg); 
		-ms-transform: translateX(0px) translateY(0px) rotate(0deg); 
		-o-transform: translateX(0px) translateY(0px) rotate(0deg); 
		transform: translateX(0px) translateY(0px) rotate(0deg); } 
	100% { 
		-webkit-transform: translateX(13px) translateY(-8px) rotate(45deg); 
		-ms-transform: translateX(13px) translateY(-8px) rotate(45deg); 
		-o-transform: translateX(13px) translateY(-8px) rotate(45deg); 
		transform: translateX(13px) translateY(-8px) rotate(45deg); }
}

@keyframes openR2 { 
	0% { opacity: 1; } 
	100% { opacity: 0; }
}

@keyframes openR3 { 
	0% { 
		-webkit-transform: translateX(0px) translateY(0px) rotate(0deg); 
		-ms-transform: translateX(0px) translateY(0px) rotate(0deg); 
		-o-transform: translateX(0px) translateY(0px) rotate(0deg); 
		transform: translateX(0px) translateY(0px) rotate(0deg); }
	100% { 
		-webkit-transform: translateX(-30px) translateY(20px) rotate(-45deg);
		-ms-transform: translateX(-30px) translateY(20px) rotate(-45deg);
		-o-transform: translateX(-30px) translateY(20px) rotate(-45deg); 
		transform: translateX(-30px) translateY(20px) rotate(-45deg); }
}

#hamburger.is-active svg #r1 {
    animation-name: openR1;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-direction: alternate;
}

#hamburger.is-active svg #r2 {
    animation-name: openR2;
    animation-duration: .1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-direction: alternate;
}

#hamburger.is-active svg #r3 {
    animation-name: openR3;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-direction: alternate;
}




/* FULLSCREENNAV
----------------------------------------------------*/

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    right: 0;
    top: 0;
    overflow-x: hidden;
    padding-top: 70px;
}

@media (min-width: 1280px) {
	.overlay {
	    padding-top: 78px;
	}
}

.overlay a.navlink {
    line-height: 1.2em;
}




/* Contentwidth
----------------------------------------------------*/

.contentwrapper {
	position: relative;
	margin: 0 8.5%; }
	
	@media (min-width: 768px) {
	.contentwrapper {
		margin: 0 8.5%; } }
		
	@media (min-width: 1400px) {
	.contentwrapper {
		margin: 0 auto;
		max-width: 1190px } }


.contentwrapper-small {
	max-width: 85%;
}

@media (min-width: 640px) {
	.contentwrapper-small {
		max-width: 75%;
	}
}

@media (min-width: 768px) {
	.contentwrapper-small {
		max-width: 65%;
	}
}

@media (min-width: 1024px) {
	.contentwrapper-small {
		max-width: 55%;
	}
}




/* IMAGES
----------------------------------------------------*/

.img-responsive {
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
}

.img-sectionicon {
	width: 5rem;
	height: 5rem;
	margin: auto;
}

@media (min-width: 640px) {
	.img-sectionicon {
		width: 6rem;
		height: 6rem;
		margin: auto;
	}
}

.img-hover-zoom {
	height: auto;
	overflow: hidden;
}

.img-hover-zoom img.lazyloaded {
	opacity: 1;
	transition: transform .5s ease, opacity .5s;
}

.img-hover-zoom:hover img.lazyloaded {
	opacity: 1;
	transform: scale(1.05);
}

/* fade image in after load */
.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity .5s;
}




/* BUTTON
----------------------------------------------------*/

.btn-brand {
	text-decoration: underline;
    text-decoration-color: transparent;
}

.btn-brand:hover,
.btn-brand:focus,
.btn-brand:active {
	text-decoration: underline;
    text-decoration-color: transparent;	
}

.btn-brand i {
	transition: color .3s ease-in-out;
}

.btn-brand:hover i {
	color: #dfe9b8;
}

.btn-pure {
    background: inherit;
	text-decoration: underline;
    text-decoration-color: transparent;
}

.btn-pure:hover {
	text-decoration: underline;
    text-decoration-color: transparent;	
}

.btn-pure span {
	color: #171717;
	text-decoration: underline;
	transition: all 0.2s ease;
    transition-property: text-decoration-color, text-decoration-thickness, text-decoration-width;
    text-decoration-color: transparent;
    text-decoration-thickness: 0.2em;
    text-decoration-width: 0.2em;
}

.btn-pure:hover span {
	text-decoration: underline;
    text-decoration-color: #e5e7eb;
    text-decoration-thickness: 0.2em;
    text-decoration-width: 0.2em;
}




/* CONTENT
----------------------------------------------------*/

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: normal;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: normal;
  /* Instead use this non-standard one: */
  word-break: normal;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

a {
	overflow-wrap:break-word;
	word-break:normal;
	word-wrap:break-word;
}




/* HEADLINES
----------------------------------------------------*/

h1, h2, h3, h4 {
	font-weight: 500;
	line-height: 1.3em;
	margin-top: 0;
	margin-bottom: 24px;
}

h2 {
	font-size: 26px;
	font-style: normal;
}
	
@media (min-width: 768px) {
	h2 {
		font-size: 30px;
	}
}

.lfc h3 {
	margin-top: 48px;
}
	
h3 {
	font-size: 24px;
	font-style: normal;
}




/* PARAGRAPHS
----------------------------------------------------*/

.link-brand > p a {
	text-decoration: none !important;
}

.link-brand > p a:hover,
.link-brand > p a:focus,
.link-brand > p a:active {
	text-decoration: underline !important;
}

p {
    margin: 0 0 24px;
    letter-spacing: 0.02em;
}

.mb-0 > p {
	margin-bottom: 0;
}

.leading-h {
	line-height: 1.3em;
}





/* LISTS
----------------------------------------------------*/

.copy ul {
	margin-bottom: 24px;
	padding-left: 20px;
	list-style-type: disc;
}

.copy ul li ul {
	margin-bottom: 0px;
}

.copy li {
	line-height: 1.8em;
}

.copy li a {
	text-decoration: none !important;
}

.copy li a:hover,
.copy li a:focus,
.copy li a:active {
	text-decoration: underline !important;
}
